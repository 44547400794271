// function vidPlayInit() {
//   const btns = document.querySelectorAll('.vid-play')
//   if (!btns.length) return

//   const toggleWtts = (id, wtts, action) => {
//     id && wtts.forEach((wtt) => wtt.classList[action]('d-none'))
//   }

//   btns.forEach(btn => {
//     const vid = btn.previousElementSibling,
//           wtts = document.querySelectorAll(`a[href="#${vid.id}"]`)
//     vid.controls = false

//     vid.addEventListener('play', () => {
//       btn.classList.add('d-none')
//       vid.controls = true
//       toggleWtts(vid.id, wtts, 'add')
//     })

//     vid.addEventListener('pause', () => {
//       if (vid.readyState != 4) return

//       vid.controls = false
//       btn.classList.remove('d-none')
//       toggleWtts(vid.id, wtts, 'remove')
//     })

//     btn.addEventListener('click', () => {
//       vid.paused && vid.play()
//     })
//   })
// }

function vidWttInit() {
  const links = document.querySelectorAll('.vid-wtt')
  if (!links.length) return

  links.forEach(link => {
    const vid = document.querySelector(link.getAttribute('href'))

    link.addEventListener('click', e => {
      e.preventDefault()
      vid?.paused && vid.play()
    })
  })
}

export { vidWttInit }
