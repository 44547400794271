import '@hotwired/turbo-rails'
// import './controllers'

import 'bootstrap/js/dist/alert'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/modal'
import Siema from 'siema'
import { flashMsgInit } from './src/flashMessages'
import { submitDisableWith } from './src/submitDisableWith'
import { imagePreview } from './src/imagePreview'
import { vidWttInit } from './src/vidPlay'


document.addEventListener('turbo:load', () => {
  flashMsgInit()
  submitDisableWith()
  window.imagePreview = imagePreview
  // vidPlayInit()
  vidWttInit()

  const sliders = document.querySelectorAll('.siema-slider')
  if (!sliders.length) return

  sliders.forEach(slider => {
    const prev = slider.nextElementSibling, next = prev?.nextElementSibling,
          options = slider.dataset.sliderOptions || '{}',
          siemaSlider = new Siema({ selector: slider, ...JSON.parse(options) })
    prev?.addEventListener('click', () => siemaSlider.prev())
    next?.addEventListener('click', () => siemaSlider.next())
  })
})

document.cookie = "time_zone=" + Intl.DateTimeFormat().resolvedOptions().timeZone + "; path=/";


