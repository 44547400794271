function imagePreview(e, type = 'src') {
  const input = e.target
  if (!input?.files?.length) return

  const preview = document.getElementById(`${input.id}-preview`), reader = new FileReader()

  reader.onload = () => {
    if (type == 'src') {
      preview.src = reader.result
    } else {
      preview.style.backgroundImage = `url(${reader.result})`
      preview.classList.add('img-bg-cv')
    }
  }
  reader.readAsDataURL(input.files[0])
}

export { imagePreview }
