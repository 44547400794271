function submitDisableWith() {
  const submits = document.querySelectorAll('input[type=submit][data-disable-with]')
  if (!submits.length) return

  submits.forEach(submit => submit.closest('form').addEventListener('submit', () => {
    submit.disabled = true
    submit.value = submit.dataset.disableWith
  }))
}

export { submitDisableWith }
